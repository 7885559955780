// src/components/About.js
import React from "react";
import "./About.css";

function About() {
  return (
    <section className="about">
      <h2>About Me</h2>
      <p>
        A bitcoiner looking to stack sats and build shit. I work very hard in
        both of those endeavors.
      </p>
    </section>
  );
}

export default About;
